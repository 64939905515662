import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sanitation-routes--show-map"
export default class extends Controller {
  static targets = ["container"];

  connect() {
    const polygonCoordinates = JSON.parse(this.element.dataset.polygon);
    const routeTrees = JSON.parse(this.element.dataset.routeTrees);
    const openEndedRouteCoordinates = JSON.parse(
      this.element.dataset.openEndedRouteCoordinates
    );

    const heatmapFieldValues = JSON.parse(
      this.element.dataset.heatmapFieldValues
    );
    const markerFieldValues = JSON.parse(
      this.element.dataset.markerFieldValues
    );
    const textFieldValues = JSON.parse(this.element.dataset.textFieldValues);

    this.map = L.map(this.containerTarget).setView([0, 0], 13);

    const maxZoomLevel = 22;

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoianVhbjI2NyIsImEiOiJjbHpsdjM4dHcwNnJhMmxvamR0eHRqa2c3In0.707gQvzDC0UxlvViBusF-g",
      {
        maxZoom: maxZoomLevel, // Higher max zoom level for Mapbox tiles
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      }
    ).addTo(this.map);

    const polygonLayer = L.geoJSON(polygonCoordinates).addTo(this.map);
    let polyLineLayer;

    // Center the map on the polygon
    const bounds = polygonLayer.getBounds();
    this.map.fitBounds(bounds);

    // Plot the route coordinates

    const routeLineCoordinates =
      openEndedRouteCoordinates.length > 0
        ? openEndedRouteCoordinates
        : routeTrees.map((tree) => tree.coordinates);

    if (routeLineCoordinates.length > 0) {
      polyLineLayer = L.polyline(routeLineCoordinates, { color: "green" });
      polyLineLayer.addTo(this.map);
    }

    const routeTreesLayer = L.layerGroup(
      routeTrees.map((routeTree, index) => {
        const marker = L.marker(routeTree.coordinates, {
          icon: new L.AwesomeNumberMarkers({
            number: index + 1,
            markerColor: "blue",
          }),
        });

        // Get text field values for this tree if they exist
        const treeTextValues = textFieldValues[routeTree.id];
        if (treeTextValues) {
          const tooltipContent = Object.entries(treeTextValues)
            .map(([key, value]) => `<li>${key}: ${value}</li>`)
            .join("");
          marker.bindTooltip(`<ul>${tooltipContent}</ul>`);
        }

        marker.on("click", () => {
          if (confirm("Quieres ir a este árbol?")) {
            window.location.href = routeTree.showViewUrl;
          }
        });
        return marker;
      })
    );

    routeTreesLayer.addTo(this.map);

    // Create heatmap layers for custom field values of type "number", "select_field", "checkbox" and "radio"
    const heatmapLayers = {};
    for (const [customFieldName, values] of Object.entries(
      heatmapFieldValues
    )) {
      const heatmapPoints = values
        .map(([lat, lon, value]) => [lat, lon, value])
        .filter(([lat, lon, value]) => value !== 0);

      const maxValue = Math.max(...values.map(([, , value]) => value));

      if (maxValue === 0) {
        continue;
      }

      heatmapLayers[`Mapa de calor: ${customFieldName}`] = L.heatLayer(
        heatmapPoints,
        {
          radius: 15,
          blur: 10,
          minOpacity: 0.4,
          gradient: {
            0.2: "blue",
            0.4: "cyan",
            0.6: "lime",
            0.8: "yellow",
            1.0: "red",
          },
        }
      ).addTo(this.map);
    }

    // Create marker layers for custom field values of type "checkbox"
    const markerLayers = {};
    for (const [customFieldName, values] of Object.entries(markerFieldValues)) {
      const markers = values.map(({ coordinates, color }) => {
        return L.circleMarker(coordinates, {
          color: color,
          fillOpacity: 1,
        }).bindPopup(customFieldName);
      });

      markerLayers[`Presencia: ${customFieldName}`] = L.layerGroup(
        markers
      ).addTo(this.map);
    }

    // Add layer controls
    L.control
      .layers(null, {
        ...heatmapLayers,
        ...markerLayers,
        ...(polyLineLayer ? { polyline: polyLineLayer } : {}),
        arboles: routeTreesLayer,
      })
      .addTo(this.map);

    // Initialize Geoman
    this.map.pm.addControls({
      position: "topleft",
      drawPolygon: false,
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawCircle: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      drawText: false,
      removalMode: false,
      editControls: false,
    });
  }

  generateUniqueColors(count) {
    const colors = new Set();
    while (colors.size < count) {
      const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      colors.add(color);
    }
    return [...colors]; // Convert to array for easier indexing
  }
}
